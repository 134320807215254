import React from 'react';
import faindiColoured from './faindiColouredExplanation.png';
import 'typeface-belleza';

const FrontpageLetter = () => {
  return (
    <div>
      <img src={faindiColoured} alt="faindiColouredExplanation" />{' '}
      <div className="font-Belleza">
      <p >
        <strong>Faindi</strong> on sovellus, jossa voit ostaa ja myydä upeita
        käsintehtyjä 
        <strong>
        {' '}vaatteita, asusteita, sisustusesineitä ja muita käsitöitä
        </strong>
        ! Me halutaan olla markkinapaikka, jossa kaikki voivat myydä omia
        tuotteitaan helposti ja turvallisesti, samalla kasvattaen
        omaa brändiään. <br />
        <br />
        Voit unohtaa massatuotetut tuotteet kokonaan ja etsiä jotain täysin
        uniikkia Faindista. Lisäksi Faindi tarjoaa myös mahdollisuuden{' '}
        <strong>ostaa ja myydä ylijäämää</strong>, kuten kangaspaloja, lankoja
        tai muita tarvikkeita. Näin voidaan omalta osaltamme tukea kestävää kulutusta ja
        kiertotaloutta. <br />
        <br /> <strong>Tuu mukaan löytämään sun uniikki tyyli!</strong>
      </p>
      </div>
    </div>
  );
};

export default FrontpageLetter;
