import React, {useState, useContext} from 'react';
import {UsersData} from '../../data/index';
import {AuthContext} from '../../authcontext';
import {useNavigate} from 'react-router-dom';

const LoginWindow: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const {setUser} = useContext(AuthContext);

  const handleLogin = (user: any) => {
    setUser(user); // Set the authenticated user in the context
    setErrorMessage(''); // Clear any existing error message
    setSuccessMessage('Login successful!');

    // Force-refresh the page
    window.location.reload();
  };

  const handleCreateAccountClick = () => {
    console.log('Create Account clicked');
    navigate('/login');
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const user = UsersData.find(
      (user) => user.email === email && user.password === password,
    );

    if (user) {
      console.log(`User ${user.name} logged in successfully!`);
      handleLogin(user);
    } else {
      console.log('Invalid email or password.');
      setErrorMessage('Salasanasi tai sähköposti on väärin.');
      setSuccessMessage(''); // clear any existing success message
    }
  };

  return (
    <div className="login-window">
      <h1 style={{textAlign: 'center'}}>
        <strong>Kirjaudu sisään tunnuksillasi</strong>
      </h1>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          className="input-field"
          placeholder="Sähköposti"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <input
          type="password"
          className="input-field"
          placeholder="Salasana"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <input type="submit" value="Kirjaudu" className="btn" />
        <input type="button" value="Unohditko salasanan?" className="btn" />
        <button
          className="mr-2 hover:text-blue-800 focus:outline-none"
          onClick={handleCreateAccountClick}
        >
          <div className="font-AbrilFatface">
            <p style={{fontSize: '15px'}}>tai luo käyttäjä</p>{' '}
            {/* Adjust the size as needed */}
          </div>
        </button>
      </form>
      {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
      {successMessage && <p style={{color: 'green'}}>{successMessage}</p>}
    </div>
  );
};

export default LoginWindow;
