import { useEffect, useRef } from 'react';
import Footer from '../components/Component/Footer';
import FrontpageUsage from '../components/Component/FrontpageUsage';
import FrontpageLetter from '../components/Component/FrontpageLetter';
import FrontpageNewsletter from '../components/Component/FrontpageNewsletter';
import FrontpagePicks from '../components/Component/FrontpagePicks';
import FrontpageSearch from '../components/Component/FrontpageSearch';
import FrontpageSocials from '../components/Component/FrontpageSocials';
import FrontpageShowcase from '../components/Component/FrontpageShowcase';
import Header from '../components/Component/Header';
import faindiBackground from './background.png';

const Root = () => {
  const showcaseRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      // Handle resize logic here if needed
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="fade-in flex min-h-screen flex-col"
      style={{
        backgroundImage: `url(${faindiBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflowX: 'hidden',
      }}
    >
      <div style={{ position: 'sticky', marginBottom: '5%', zIndex: 500 }}>
        <Header />
      </div>
      <div className="relative flex w-full flex-1 items-start justify-center pb-16">
        <div className="max-w-screen-3xl flex flex-col justify-center md:flex-row">
          <div
            className="flex flex-col items-center"
            ref={showcaseRef}
            style={{
              width: '100%',
              margin: '0 auto',
              marginTop: '50px',
            }}
          >
            <div style={{ maxWidth: '300px', width: '100%' }}>
              <FrontpageShowcase />
            </div>
            <div style={{ maxWidth: '800px', width: '100%' }}>
              <FrontpageSocials />
              <FrontpageNewsletter />
            </div>
          </div>
          <div
            className="mx-auto bg-transparent p-8 md:w-auto"
            ref={searchRef}
            style={{ width: '100%', maxWidth: '700px' }}
          >
            <FrontpageSearch />
            <FrontpageLetter />
            <div
            className="mx-auto bg-transparent p-8 md:w-auto"
            style={{ width: '100%', marginTop: '15%' }}
          >
            <FrontpageUsage />
          </div>
          </div>
          <div
            className="mx-auto flex flex-col items-center bg-transparent p-8 md:w-auto"
            style={{ width: '100%', maxWidth: '300px' }}
          >
            <FrontpagePicks />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Root;
