import React from 'react';
import faindiBackground from './background.png';
import { css } from '@emotion/react';
import { SyncLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
`;

const LoadingScreen = () => {
  return (
    <div
      className="flex flex-col items-center justify-center h-screen bg-loading-screen bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${faindiBackground})` }}
    >
      <div style={{ display: 'block', margin: '0 auto' }}>
        <SyncLoader color="#ffffff" size={15}/>
      </div>
    </div>
  );
};

export default LoadingScreen;
