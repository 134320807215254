import React, { useEffect, useRef, useState } from 'react';
import Footer from '../components/Component/Footer';
import FrontpageLetter from '../components/Component/FrontpageLetter';
import FrontpageUsageMobile from '../components/Component/FrontpageUsageMobile';
import FrontpageNewsletter from '../components/Component/FrontpageNewsletter';
import FrontpageSearch from '../components/Component/FrontpageSearch';
import FrontpageSocials from '../components/Component/FrontpageSocials';
import FrontpageShowcase from '../components/Component/FrontpageShowcase';
import Header from '../components/Component/Header';
import faindiBackground from './background.png';

const MobileRoot = () => {
  const showcaseRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const getStyleForScreen = (screenWidth: number) => {
    let maxWidth;
    let marginTop;
    let headerHeight;
    let contentMarginX;

    if (screenWidth > 700) {
      maxWidth = '600px';
      marginTop = '100px';
      headerHeight = '150px';
      contentMarginX = '20px'; // Increase the horizontal margin
    } else {
      maxWidth = '350px';
      marginTop = '50px';
      headerHeight = '120px';
      contentMarginX = '40px'; // Increase the horizontal margin for mobile
    }

    return { maxWidth, marginTop, headerHeight, contentMarginX };
  };

  const {
    maxWidth: searchMaxWidth,
    marginTop: searchMarginTop,
    headerHeight,
    contentMarginX,
  } = getStyleForScreen(screenWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="fade-in flex min-h-screen flex-col items-center justify-center"
      style={{
        backgroundImage: `url(${faindiBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflowX: 'hidden',
      }}
    >
      <div
        style={{
          position: 'sticky',
          top: 0,
          marginBottom: '5%',
          zIndex: 500,
          height: headerHeight,
        }}
      >
        <Header />
      </div>
      <div
        className="relative flex w-full flex-1 items-start justify-center pb-16"
        style={{ margin: `0 ${contentMarginX}` }} // Apply the horizontal margin to the content
      >
        <div className="max-w-screen-3xl flex flex-col justify-center md:flex-row">
          <div
            className="mx-auto bg-transparent p-4 md:w-1/2 md:p-8"
            ref={searchRef}
            style={{
              maxWidth: searchMaxWidth,
              marginTop: searchMarginTop,
              order: 1,
              margin: `0 ${contentMarginX}`, // Apply the horizontal margin
            }}
          >
            <FrontpageSearch />
            <FrontpageLetter />
            <FrontpageSocials />
            <FrontpageShowcase />
            <FrontpageNewsletter />
            <FrontpageUsageMobile />
          </div>
          <div
            className="flex flex-col items-center"
            ref={showcaseRef}
            style={{
              width: '100%',
              marginTop: searchMarginTop,
              order: 2,
            }}
          >
            <div style={{ maxWidth: searchMaxWidth, width: '100%' }}>
              {/* <FrontpageCategories /> */}
            </div>
          </div>
        </div>
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 100,
          }}
        ></div>
      </div>
      <Footer />
    </div>
  );
};

export default MobileRoot;
