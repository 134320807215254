import React, { useState, useEffect, useContext } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WorldFlag from 'react-world-flags';
import axios from 'axios';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../authcontext';

import headerHome from './headerHome.svg';
import headerAdd from './headerSignUp.svg';
import headerPlus from './headerPlus.svg';
import headerChat from './headerChat.svg';
import LoginWindow from './LoginWindow';
import HeaderMobile from './HeaderMobile';

const Header = () => {
  const { user } = useContext(AuthContext);
  const [isNavVisible, setNavVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [userCountry, setUserCountry] = useState('');
  const [isLoginVisible, setLoginVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json');
        const { country } = response.data;
        setUserCountry(country);
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };

    fetchUserCountry();
  }, []);

  const handleLoginClick = () => {
    setLoginVisible(!isLoginVisible);
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleCreateAccountClick = () => {
    console.log('Create Account clicked');
    navigate('/login');
  };

  const portalRoot = document.getElementById('portal-root');

  const navButtonsRow = (
    <div className="mr-64 hidden items-center justify-center space-x-2 text-sm md:flex">
      {!user && (
        <button
          className="mr-2 hover:text-blue-800 focus:outline-none"
          onClick={handleCreateAccountClick}
        >
          <div className="font-AbrilFatface">
          <p>Luo profiili</p>
          </div>
        </button>
      )}
      <button
        className="mr-2 hover:text-blue-800 focus:outline-none"
        onClick={handleHomeClick}
      >
        <img src={headerHome} alt="Home" className="h-10 w-10 text-black" />
      </button>
      <button
        className="mr-2 hover:text-blue-800 focus:outline-none"
        onClick={handleLoginClick}
      >
        <img src={headerAdd} alt="Add" className="h-10 w-10 text-black" />
      </button>
      <button
        className="mr-2 hover:text-blue-800 focus:outline-none"
        onClick={handleLoginClick}
      >
        <img src={headerPlus} alt="Plus" className="h-10 w-10 text-black" />
      </button>
      <button
        className="mr-2 hover:text-blue-800 focus:outline-none"
        onClick={handleLoginClick}
      >
        <img src={headerChat} alt="Chat" className="h-10 w-10 text-black" />
      </button>
    </div>
  );

  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  return (
    <>
      {isMobile ? (
        <HeaderMobile />
      ) : (
        <header
          className="fixed left-0 right-0 top-0 z-10 p-5 shadow-md transition-all duration-300"
          style={{ backgroundColor: isScrolled ? '#AE95B2' : 'transparent' }}
        >
          <nav className="flex w-full flex-col items-end justify-center">
            <div className="absolute right-0 top-0 m-2 md:hidden">
              <FontAwesomeIcon
                icon={faBars}
                onClick={() => setNavVisible(!isNavVisible)}
              />
            </div>
            {isNavVisible ? (
              <div className="fixed right-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-blue-500 p-5 text-white">
                {navButtonsRow}
              </div>
            ) : (
              navButtonsRow
            )}
            {userCountry && (
              <div className="absolute right-4 top-1/2 -translate-y-1/2 transform">
                <button
                  className="button-like"
                  onClick={(e) => e.preventDefault()}
                >
                  <WorldFlag
                    className="flag-icon"
                    code={userCountry}
                    style={{ width: '2.5rem' }}
                  />
                </button>
              </div>
            )}
            {user && (
              <div className="absolute inset-x-0 top-8 mx-auto text-center text-white">
                <p className="text-lg font-bold">
                  Tervetuloa, {user.username}!
                </p>
              </div>
            )}
          </nav>
          {isLoginVisible &&
            portalRoot &&
            createPortal(<LoginWindow />, portalRoot)}
        </header>
      )}
    </>
  );
};

export default Header;
