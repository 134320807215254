import React from 'react';
import faindiStageOne from './faindiStageOne.svg';
import faindiStageThree from './faindiStageThree.svg';
import faindiStageFour from './faindiStageFour.svg';
import faindiStageFive from './faindiStageFive.svg';
import faindiStageSix from './faindiStageSix.svg';

const FrontpageUsage = () => {
  return (
    <div
      style={{
        background:
          'linear-gradient(to bottom, #D9CDD7 0%, rgba(217, 205, 215, 0.7) 100%)',
        borderRadius: '10%',
        marginLeft: '-20%',
        padding: '15px',
        fontFamily: 'Belleza, sans-serif',
        width: '140%',
        overflow: 'hidden', // Hide any content that exceeds the circular shape
        alignContent: 'left',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          padding: '8px',
          alignItems: 'flex-start', // Align items at the top of the container
          fontFamily: 'Belleza, sans-serif', // Apply Belleza font to all text
        }}
      >
        <div
          style={{
            width: '30%',
            textAlign: 'center',
            marginBottom: '8px',
            marginRight: '5px',
          }}
        >
          <img
            src={faindiStageSix}
            alt="Faindi Stage One"
            style={{width: '100%', height: 'auto'}}
          />
          <h3 style={{fontWeight: 'bold'}}>Lataa Faindi puhelimeesi</h3>
          <ul
            style={{
              listStyleType: 'disc',
              textAlign: 'left',
              marginLeft: '20px',
            }}
          >
            <li>Lataa ja asenna Faindi-applikaatio sovelluskaupasta</li>
          </ul>
        </div>
        <div
          style={{
            width: '30%',
            textAlign: 'center',
            marginBottom: '8px',
            marginRight: '5px',
          }}
        >
          <img
            src={faindiStageFive}
            alt="Faindi Stage Three"
            style={{width: '100%', height: 'auto'}}
          />
          <h3 style={{fontWeight: 'bold'}}>Luo käyttäjätili</h3>
          <ul
            style={{
              listStyleType: 'disc',
              textAlign: 'left',
              marginLeft: '20px',
            }}
          >
            <li>Avaa applikaatio ja valitse "Luo tili"</li>
            <li>Syötä tarvittavat tiedot ja hyväksy käyttäjäehdot</li>
          </ul>
        </div>
        <div
          style={{
            width: '30%',
            textAlign: 'center',
            marginBottom: '8px',
            marginRight: '5px',
          }}
        >
          <img
            src={faindiStageFour}
            alt="Faindi Stage Four"
            style={{width: '100%', height: 'auto'}}
          />
          <h3 style={{fontWeight: 'bold'}}>Selaa ja osta tuotteita</h3>
          <ul
            style={{
              listStyleType: 'disc',
              textAlign: 'left',
              marginLeft: '20px',
            }}
          >
            <li>Selaa kategorioita ja tutustu valikoimaan</li>
            <li>Lisää haluamasi tuotteet ostoskoriin</li>
            <li>Valitse maksu- ja toimitustapa tuotteille</li>
          </ul>
        </div>
        <div
          style={{
            width: '33%',
            textAlign: 'center',
            marginBottom: '8px',
            marginRight: '5px',
          }}
        >
          <img
            src={faindiStageThree}
            alt="Faindi Stage Five"
            style={{width: '100%', height: 'auto'}}
          />
          <h3 style={{fontWeight: 'bold'}}>Myy omia tuotteitasi</h3>
          <ul
            style={{
              listStyleType: 'disc',
              textAlign: 'left',
              marginLeft: '20px',
            }}
          >
            <li>Lisää omia tuotteitasi myyntiin painamalla '+' kohdasta</li>
            <li>Määrittele hinnat sekä toimitusehdot</li>
            <li>Seuraa myyntejäsi hallinnoi tiliäsi Faindissa</li>
          </ul>
        </div>
        <div style={{width: '33%', textAlign: 'center', marginBottom: '20px'}}>
          <img
            src={faindiStageOne}
            alt="Faindi Stage Six"
            style={{width: '100%', height: 'auto'}}
          />
          <h3 style={{fontWeight: 'bold'}}>
            Seuraa tilauksiasi ja nauti käsityöstä
          </h3>
          <ul
            style={{
              listStyleType: 'disc',
              textAlign: 'left',
              marginLeft: '20px',
            }}
          >
            <li>Seuraa tilausten etenemistä</li>
            <li>Ota yhteyttä myyjiin tai asiakaspalveluun tarvittaessa</li>
            <li>Inspiroidu käsityömaailmasta ja löydä sun uniikki tyyli!</li>
          </ul>
        </div>
        <div
          style={{
            width: '30%',
            textAlign: 'left',
            marginBottom: '10px',
            marginTop: '26%',
            marginRight: '-20px',
            backgroundColor: 'rgba(255, 255, 255, 1.0)',
            borderRadius: '20% 20% 20% 20%', // Circular shape from the top
          }}
        >
          <div
            style={{
              width: '100%',
              padding: '2px',
              textAlign: 'center',
            }}
          >
            <p
              style={{
                marginTop: '10%',
                color: '#D9CDD7',
                fontSize: '18px', // Increase the font size
                fontWeight: 'bold',
              }}
            >
              Nyt voit hyödyntää Faindia ostoksen tekemiseen ja omien
              käsitöidesi myyntiin helposti ja kätevästi!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontpageUsage;
