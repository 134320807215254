import React, {useEffect, useRef, useState} from 'react';
import showcaseImage from './faindi.png';
import video1 from '../../assets/video1.MOV';
import video2 from '../../assets/video2.MOV';
import video3 from '../../assets/video3.MOV';
import video4 from '../../assets/video4.MOV';
import video5 from '../../assets/video5.MOV';
import {UsersData} from '../../data/index';

import SquareIcon from '../../assets/backbtn.png';
import BackIcon from '../../assets/squarebtn.png';

interface FrontpageShowcaseProps {}

const FrontpageShowcase: React.FC<FrontpageShowcaseProps> = () => {
  const videos = [
    {
      id: 1,
      path: video1,
      userId: 1, // User ID associated with Video ID 1
    },
    {
      id: 2,
      path: video2,
      userId: 2, // User ID associated with Video ID 2
    },
    {
      id: 3,
      path: video3,
      userId: 3, // User ID associated with Video ID 3
    },
    {
      id: 4,
      path: video4,
      userId: 4, // User ID associated with Video ID 4
    },
    {
      id: 5,
      path: video5,
      userId: 5, // User ID associated with Video ID 5
    },
  ];

  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);

  const progressBarInterval = useRef<NodeJS.Timeout | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const nextVideoRef = useRef<HTMLVideoElement>(null);

  const currentVideo = videos[currentVideoIndex];
  const currentUser = UsersData.find((user) => user.id === currentVideo.userId);

  useEffect(() => {
    videos.forEach((video) => {
      const videoObject = document.createElement('video');
      videoObject.src = video.path;
    });
  }, []);

  useEffect(() => {
    progressBarInterval.current = setInterval(() => {
      if (videoRef.current && videoRef.current.duration) {
        const currentTime = videoRef.current.currentTime;
        const duration = videoRef.current.duration;
        const calculatedProgress = (currentTime / duration) * 100;
        setProgress(calculatedProgress);
      }
    }, 100);

    return () => {
      if (progressBarInterval.current) {
        clearInterval(progressBarInterval.current);
      }
    };
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setIsTransitioning(false);
    }
  }, [progress]);

  const handleVideoEnd = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentVideoIndex((prevIndex: number) => {
        const nextIndex: number = (prevIndex + 1) % videos.length;
        return nextIndex;
      });
      setIsTransitioning(false);
    }, 10); // Change the delay to match the extended transition duration

    setProgress(0);
  };

  return (
    <div className="mobile-phone-container">
      <div
        className={`relative flex h-auto w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-white bg-opacity-70 shadow-lg ${
          isTransitioning && !isVideoLoaded
            ? 'transition-opacity duration-500'
            : ''
        }`}
      >
        <div className="relative flex h-96 w-full items-center justify-center overflow-hidden">
          <video
            autoPlay
            muted
            playsInline
            src={currentVideo.path}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: isTransitioning ? '0' : '1',
              transform: isTransitioning
                ? 'translateX(-100%)'
                : 'translateX(0)',
              transition: 'opacity 0.5s, transform 0.5s', // Add the fading transition
              zIndex: isTransitioning ? '1' : '0',
            }}
            ref={videoRef}
            onLoadedData={() => setIsVideoLoaded(true)}
            onEnded={handleVideoEnd}
          >
            Your browser does not support the video tag.
          </video>

          <video
            autoPlay
            muted
            playsInline
            src={videos[(currentVideoIndex + 1) % videos.length].path}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: isTransitioning ? '1' : '0',
              transform: isTransitioning ? 'translateX(0)' : 'translateX(100%)',
              transition: 'opacity 0.5s, transform 0.5s', // Add the fading transition
              zIndex: isTransitioning ? '0' : '1',
            }}
            ref={nextVideoRef}
          >
            Your browser does not support the video tag.
          </video>

          <div className="absolute bottom-0 left-0 right-0 h-2 bg-gray-200">
            <div
              className="h-full bg-red-200 transition-all"
              style={{width: `${progress}%`}}
            ></div>
          </div>
          <div className="absolute top-2 left-2 text-sm font-bold text-white">
            {currentUser && (
              <div className="flex items-center">
                <div className="mr-2 rounded p-1">
                  <img
                    className="h-12 w-12 rounded-full"
                    src={currentUser.avatar}
                    alt={currentUser.username}
                  />
                </div>
                <div className="rounded bg-white bg-opacity-30 p-1">
                  @käyttäjä
                  {/* {currentUser.username} */}
                </div>
              </div>
            )}
          </div>
        </div>
        <div style={{height: '10%', width: '50%'}}>
          <img
            alt="showcaseImage"
            className="h-full w-full"
            src={showcaseImage}
          />
        </div>
        {/* Mobile Phone Square and Back buttons */}
        <div className="absolute bottom-0 left-4 right-4 flex justify-between">
          <div className="text-white">
            <img src={SquareIcon} alt="Back" />
          </div>
          <div className="text-white">
            <img src={BackIcon} alt="Back" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontpageShowcase;
