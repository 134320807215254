import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

import googlePlay from './googleplay.png';
import appStore from './appstore.png';
import androidAPK from './faindiapk.png';
import googlePlayHovered from './googleplayHovered.png';
import appStoreHovered from './appstoreHovered.png';
import androidAPKHovered from './faindiapkHovered.png';

const categories = [
  'vaatteita',
  'koristeita',
  'tarvikkeita',
  'lankoja',
  'paitoja',
  'löytöjä',
];

const FrontpageSearch: React.FC = () => {
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    `Browse Faindi for ${categories[categoryIndex]}...`,
  );

  const [googlePlayHover, setGooglePlayHover] = useState(false);
  const [appStoreHover, setAppStoreHover] = useState(false);
  const [androidAPKHover, setAndroidAPKHover] = useState(false);

  const handleGooglePlayMouseEnter = () => {
    setGooglePlayHover(true);
  };

  const handleGooglePlayMouseLeave = () => {
    setGooglePlayHover(false);
  };

  const handleAppStoreMouseEnter = () => {
    setAppStoreHover(true);
  };

  const handleAppStoreMouseLeave = () => {
    setAppStoreHover(false);
  };

  const handleAndroidAPKMouseEnter = () => {
    setAndroidAPKHover(true);
  };

  const handleAndroidAPKMouseLeave = () => {
    setAndroidAPKHover(false);
  };

  const handleStoreClick = (event: React.MouseEvent) => {
    event.preventDefault();
    alert(
      'Hei, sovelluksemme on viimeistelyvaiheessa. Liity uutiskirjeeseen, niin saat tiedon kun sovellus on ladattavissa! 😊💮',
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCategoryIndex((prevIndex) => (prevIndex + 1) % categories.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const category = categories[categoryIndex];

    let currentIndex = 0;
    const categoryLength = category.length;

    const typewriterInterval = setInterval(() => {
      setSearchPlaceholder((prevPlaceholder) => {
        const lastWord = prevPlaceholder.split(' ').pop() || '';

        const placeholder =
          prevPlaceholder.substring(
            0,
            prevPlaceholder.length - lastWord.length,
          ) + category.substring(0, currentIndex + 1);

        currentIndex++;

        if (currentIndex >= categoryLength) {
          clearInterval(typewriterInterval);
          return `Browse Faindi for ${category}...`;
        }

        return placeholder;
      });
    }, 100);

    return () => clearInterval(typewriterInterval);
  }, [categoryIndex]);

  const gradientStyle: React.CSSProperties = {
    fontFamily: 'Abril Fatface',
    fontWeight: 'bold',
    fontSize: '7rem',
    lineHeight: '1.0',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const gradientSpanStyle: React.CSSProperties = {
    backgroundImage: 'linear-gradient(to right, #0F0F0F, #0F0F0F, #0F0F0F)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    fontWeight: 'bold',
    flexDirection: 'column',
    lineHeight: '1.0',
  };

  const searchbarStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3rem',
  };

  const inputContainerStyle: React.CSSProperties = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  };

  const searchIconStyle: React.CSSProperties = {
    position: 'absolute',
    left: '0.5rem',
    color: '#888',
    fontSize: '1.2rem',
  };

  const inputStyle: React.CSSProperties = {
    width: '100%',
    maxWidth: '34rem',
    height: '3rem',
    padding: '0.5rem',
    paddingLeft: '2rem',
    borderRadius: '0.5rem',
    border: 'none',
    outline: 'none',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0, 0.1)',
    fontFamily: 'Belleza, cursive',
    fontSize: '1.55rem',
    fontStyle: 'italic', // This will make the text italic
    fontWeight: 'light', // This will make the text non-bolded
  };
  const imagesContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.1rem',
  };

  const imageStyle: React.CSSProperties = {
    width: '9em',
    height: '9rem',
    objectFit: 'contain',
  };

  const textContainerStyle: React.CSSProperties = {
    marginTop: '1rem',
    textAlign: 'center',
  };

  return (
    <div style={gradientStyle}>
      <div style={textContainerStyle}>
        Löydä <span style={gradientSpanStyle}>uniikki</span> tyylisi
      </div>
      <div style={searchbarStyle}>
        <div style={inputContainerStyle}>
          <FontAwesomeIcon icon={faSearch} style={searchIconStyle} />
          <input
            type="text"
            placeholder={`Etsi ${searchPlaceholder.split(' ').pop()}`}
            style={inputStyle}
          />
        </div>
      </div>
      <div style={imagesContainerStyle}>
        <a
          onClick={handleStoreClick}
          onMouseEnter={handleGooglePlayMouseEnter}
          onMouseLeave={handleGooglePlayMouseLeave}
        >
          <img
            src={googlePlayHover ? googlePlayHovered : googlePlay}
            alt="Google Play"
            style={imageStyle}
          />
        </a>
        <a
          onClick={handleStoreClick}
          onMouseEnter={handleAppStoreMouseEnter}
          onMouseLeave={handleAppStoreMouseLeave}
        >
          <img
            src={appStoreHover ? appStoreHovered : appStore}
            alt="App Store"
            style={imageStyle}
          />
        </a>
        <a
          onClick={handleStoreClick}
          onMouseEnter={handleAndroidAPKMouseEnter}
          onMouseLeave={handleAndroidAPKMouseLeave}
        >
          <img
            src={androidAPKHover ? androidAPKHovered : androidAPK}
            alt="Faindi APK"
            style={imageStyle}
          />
        </a>
      </div>
    </div>
  );
};

export default FrontpageSearch;
