import React from 'react';
import {faInstagram, faTiktok} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


const FrontpageSocials = () => {
  return (
    <div className="mt-10 mb-20 flex flex-col items-center justify-center">
      <h2 className="mb-4 mt-3 mb-4 text-1xl font-Belleza">
        Seuraa meitä myös somessa!
      </h2>
      <div className="flex items-center justify-center space-x-4">
        <a
          href="https://www.instagram.com/faindisuomi/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faInstagram} size="4x" />
        </a>
        <a
          href="https://www.tiktok.com/@faindisuomi"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faTiktok} size="4x" />
        </a>
      </div>
    </div>
  );
};

export default FrontpageSocials;
