import React, {useState} from 'react';
import axios from 'axios';
import faindiSymbolTilted from './faindiSymbolTilted.png';

const FrontpageNewsletter = () => {
  const HeaderTextStyle: React.CSSProperties = {
    fontFamily: 'Belleza',
    fontWeight: 'bold',
    fontSize: '2rem',
    lineHeight: '1.0',
    color: 'black',
    textAlign: 'left',
    marginBottom: '2rem',
  };

  const FormStyle: React.CSSProperties = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    padding: '2rem',
    borderRadius: '8px',
    maxWidth: '300px',
    margin: 'auto', 
    marginBottom: '15%',
  };

  const InputStyle: React.CSSProperties = {
    margin: '1rem 0',
    padding: '1rem',
    borderRadius: '4px',
    border: '1px solid #D1D5DB',
    width: '100%',
  };

  const ButtonStyle: React.CSSProperties = {
    cursor: 'pointer',
    backgroundColor: '#AD96B5',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '1rem',
    width: '100%',
    marginTop: '1rem',
    opacity: '0.5',
    pointerEvents: 'none',
  };

  const ImageStyle: React.CSSProperties = {
    position: 'absolute',
    top: '-3%',
    right: '-10%',
    width: '80px',
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(false);
  const [seller, setSeller] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (consent) {
      axios
        .post('https://faindibackend-production.up.railway.app/subscribe', {name, email, seller})
        .then(() => {
          console.log('Subscribed successfully!');
          setName('');
          setEmail('');
          setConsent(false);
          setSeller(false);
          setSubscribed(true);
        })
        .catch((error) => {
          console.log('There was an error:', error);
        });
    } else {
      alert('Please consent to being on the maillist');
    }
  };

  const updatedButtonStyle: React.CSSProperties = {
    ...ButtonStyle,
    font: '1.2rem Belleza',
    opacity: consent ? 1 : 0.5,
    pointerEvents: consent ? 'auto' : ('none' as any),
  };

  return (
    <div style={FormStyle}>
      <img src={faindiSymbolTilted} alt="Faindi symbol" style={ImageStyle} />
      <h2 style={HeaderTextStyle}>Tilaa meidän uutiskirje, ja pysy mukana!</h2>
      <form onSubmit={handleSubmit}>
        <label className="mb-4 mt-3 mb-4 text-1xl font-Belleza">
          *Etunimesi:
          <input
            style={InputStyle}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>
        <label className="mb-4 mt-3 mb-4 text-1xl font-Belleza">
          *Sähköposti:
          <input
            style={InputStyle}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <label className="mb-4 mt-3 mb-4 text-1xl font-Belleza">
          <input
            type="checkbox"
            checked={consent}
            onChange={() => setConsent(!consent)}
          />{' '}
          *Tietojani saa käsitellä uutiskirjeen lähettämistä ja markkinointia
          varten.
        </label>
        <br />
        {' '}
        <label className="mb-4 mt-3 mb-4 text-sm font-Belleza">
          {' '}
          <input
            type="checkbox"
            checked={seller}
            onChange={() => setSeller(!seller)}
          />{' '}
          Olen myös kiinnostunut myyjänä toimimisesta Faindissa.
        </label>
        <button style={updatedButtonStyle} type="submit">
          Kyllä!
        </button>
        {subscribed && (
          <p style={{color: 'green', marginTop: '1rem'}}>
            Kiitos! Olet nyt listallamme.
          </p>
        )}
      </form>
    </div>
  );
};

export default FrontpageNewsletter;
