import React, {useEffect, useRef, useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import imageBackground from './polaroid.png';
import clothingItem1 from './clothingItem1.png';
import clothingItem2 from './clothingItem6.png';
import clothingItem3 from './shoes.jpg';
import clothingItem4 from './socks.jpg';
import clothingItem5 from './clothingItem5.png';
import clothingItem6 from './clothingItem6.jpg';

const FrontpagePicks: React.FC = () => {
  const picksTextStyle = {
    fontFamily: 'Abril Fatface',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  };

  const containerStyle: React.CSSProperties = {
    position: 'relative',
  };

  const imageOverlayStyle: React.CSSProperties = {
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
    height: '100%',
    zIndex: 2,
  };

  const clothingItemStyle: React.CSSProperties = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
    borderRadius: '8px',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
  };

  const [visibleCount, setVisibleCount] = useState(3);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastImageElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setVisibleCount((prevCount) => {
          if (prevCount < 5) {
            return prevCount + 1;
          }
          return prevCount;
        });
      }
    });
    if (lastImageElementRef.current) {
      observer.current.observe(lastImageElementRef.current);
    }
    return () => {
      if (observer.current && lastImageElementRef.current) {
        observer.current.unobserve(lastImageElementRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const shuffleInterval = setTimeout(() => {
      setVisibleCount((prevCount) => {
        if (prevCount < 5) {
          return prevCount + 1;
        }
        return 1;
      });
    }, 2000); // Shuffle interval duration in milliseconds

    return () => {
      clearTimeout(shuffleInterval);
    };
  }, [visibleCount]);

  const renderImages = () => {
    const images = [];
    for (let i = 0; i < 5; i++) {
      let clothingItem;
      switch (i % 9) {
        case 0:
          clothingItem = clothingItem1;
          break;
        case 1:
          clothingItem = clothingItem2;
          break;
        case 2:
          clothingItem = clothingItem3;
          break;
        case 3:
            clothingItem = clothingItem4;
          break;
        case 4:
            clothingItem = clothingItem5;
          break;
        case 6:
            clothingItem = clothingItem6;
          break;
        default:
          clothingItem = clothingItem1;
      }
      const isVisible = i < visibleCount;
      const imageVariants = {
        visible: {opacity: 1, y: 0},
        hidden: {opacity: 0, y: -30},
      };

      images.push(
        <motion.div
          key={i}
          className="flex flex-col gap-4"
          style={containerStyle}
          ref={i === visibleCount - 1 ? lastImageElementRef : null}
          initial="hidden"
          animate={isVisible ? 'visible' : 'hidden'}
          variants={imageVariants}
          transition={{duration: 0.5, ease: 'easeInOut'}}
        >
          <div style={imageOverlayStyle}>
            <img
              src={imageBackground}
              alt="Polaroid Container"
              className="h-full w-full"
            />
          </div>
          <img
            src={clothingItem}
            alt={`Placeholder Image ${i + 1}`}
            style={clothingItemStyle}
          />
        </motion.div>,
      );
    }

    return images;
  };

  return (
    <div className="md:w-4/3 mx-4 flex-1 bg-transparent p-4">
      <AnimatePresence>
        <div className="flex flex-col gap-4">{renderImages()}</div>
      </AnimatePresence>
    </div>
  );
};

export default FrontpagePicks;
