import userIcon from '../assets/imgs/user_icon.png';

export interface User {
  id: number;
  name: string;
  email: string;
  username: string;
  password: string;
  avatar: string;
}

interface Category {
  icon: string;
  text: string;
}

interface DummyDataItem {
  id: number;
  title: string;
  description: string;
  price: number;
  userId: number;
  category: string;
  image: string;
  likes: number;
  size?: string;
  colour?: string;
  preOwned?: boolean;
}

export const UsersData: User[] = [
  {
    id: 1,
    name: 'melissamuumi',
    email: '1@1',
    username: '@melissamuumi',
    password: '1',
    avatar: userIcon,
  },
  {
    id: 2,
    name: 'vappupimiä',
    email: 'vappu.pimiä@testi.fi',
    username: '@vappupimiä',
    password: 'Alex2',
    avatar: userIcon,
  },
  {
    id: 3,
    name: 'minnihiiri',
    email: 'minni.hiiri@testi.fi',
    username: '@minnihiiri',
    password: 'Alex3',
    avatar: userIcon,
  },
  {
    id: 4,
    name: 'janettakos',
    email: 'janetta.kos@testi.fi',
    username: '@janettakos',
    password: 'Alex4',
    avatar: userIcon,
  },
  {
    id: 5,
    name: 'eerinmeerin',
    email: 'eerin.meerin@testi.fi',
    username: '@eerinmeerin',
    password: 'Alex5',
    avatar: userIcon,
  },
  {
    id: 6,
    name: 'aleksi',
    email: 'aleksi@definationlabs.io',
    username: '@aleksi',
    password: 'Alex6',
    avatar: userIcon,
  },
];
