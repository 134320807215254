import React, {useContext, useState, useEffect} from 'react';
import {AuthContext, User} from '../authcontext';
import {useNavigate} from 'react-router-dom';
import Header from '../components/Component/Header';
import Footer from '../components/Component/Footer';
import faindiBackground from './background.png';

const Login: React.FC = () => {
  const {user} = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log('Username:', username);
    console.log('Password:', password);
    // handle sign-up logic here
  };

  useEffect(() => {
    const handleResize = () => {
      // Handle resize logic here if needed
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (user) {
      // User is already logged in, show warning and redirect after 2 seconds
      setShowWarning(true);
      setTimeout(() => {
        setShowWarning(false);
        navigate('/');
      }, 2000);
    }
  }, [user, navigate]);

  return (
    <div
      className="fade-in flex min-h-screen flex-col"
      style={{
        backgroundImage: `url(${faindiBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflowX: 'hidden',
      }}
    >
      <div style={{position: 'sticky', marginBottom: '5%', zIndex: 500}}>
        <Header />
      </div>
      <div className="flex flex-1 items-center justify-center">
        <div className="w-full max-w-md px-4">
          <h1 className="mb-4 text-2xl font-Belleza text-center">Hei! Kiitos kiinnostuksestasi. Et voi vielä tehdä käyttäjää Faindiin, mutta jos olet esirekisteröitynyt käyttäjä tai testaaja, pääset sisään syöttämällä tunnuksesi.</h1>
          {showWarning && (
            <div className="mb-4 text-red-500">
              You are already logged in. Redirecting...
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="mb-2 block font-Belleza text-center">
                Sähköposti (nimi@faindiapp.fi):
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="block w-full rounded border px-2 py-1"
                />
              </label>
            </div>
            <div className="mb-4">
              <label className="mb-2 block font-Belleza text-center">
                Salasana:
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded border px-2 py-1"
                />
              </label>
            </div>
            <div>
              <input
                type="submit"
                value="Syötä"
                className="w-full rounded bg-red-500 px-4 py-2 text-white"
              />
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
