import React, { useEffect, useRef, useState } from 'react';
import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useIntersectionObserver = (ref: React.MutableRefObject<any>, options: IntersectionObserverInit) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, options]);

  return isIntersecting;
};

const Footer = () => {
  const footerRef = useRef<HTMLDivElement>(null);
  const isGlanced = useIntersectionObserver(footerRef, {});

  return (
    <footer className="relative bg-red-100 p-8 text-center text-black">
      <div className="absolute left-0 top-1/2 -translate-y-1/2 transform pl-4">
        {isGlanced && (
          <div className="transition-opacity duration-300 opacity-100" ref={footerRef}></div>
        )}
      </div>

      <div className={`flex items-center justify-center space-x-4 ${isGlanced ? 'animate-slide-up' : ''}`}>
        <div className="flex items-center space-x-4 font-AbrilFatface">
          <button className="text-white-400">Asiakastuki</button>
          <button className="text-white-400">Tietosuoja</button>
          <button className="text-white-400">Käyttöehdot</button>
          <a href="https://www.instagram.com/faindisuomi" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faInstagram} size="xl" />
          </a>
          <a href="https://www.tiktok.com/@faindisuomi" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faTiktok} size="xl" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
