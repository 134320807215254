import React from 'react';

export interface User {
  id: number;
  name: string;
  email: string;
  username: string;
  password: string;
  avatar: string;
}

interface AuthContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  handleLogin: (username: string, password: string) => void;
  handleLogout: () => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  setUser: () => {},
  handleLogin: () => {},
  handleLogout: () => {},
});
