import React, { useState, useEffect, useContext } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WorldFlag from 'react-world-flags';
import axios from 'axios';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../authcontext';

import headerHome from './headerHome.svg';
import headerAdd from './headerSignUp.svg';
import headerPlus from './headerPlus.svg';
import headerChat from './headerChat.svg';
import LoginWindow from './LoginWindow';

const HeaderMobile = () => {
  const { user } = useContext(AuthContext);
  const [isNavVisible, setNavVisible] = useState(false);
  const [userCountry, setUserCountry] = useState('');
  const [isLoginVisible, setLoginVisible] = useState(false);
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleLoginClick = () => {
    setLoginVisible(!isLoginVisible);
  };

  const handleHomeClick = () => {
    navigate('/');
    setNavVisible(false);
  };

  const handleCreateAccountClick = () => {
    console.log('Create Account clicked');
    navigate('/login');
  };

  const portalRoot = document.getElementById('portal-root');

  const navButtonsRow = (
    <div className="flex flex-col items-center justify-center space-y-2 text-sm">
      {!user && (
        <button
          className="hover:text-blue-800 focus:outline-none"
          onClick={handleCreateAccountClick}
        >
          <div className="font-AbrilFatface">
            <p>Luo profiili</p>
          </div>
        </button>
      )}
      <button
        className="hover:text-blue-800 focus:outline-none"
        onClick={handleHomeClick}
      >
        <img src={headerHome} alt="Home" className="h-10 w-10 text-black" />
      </button>
      <button
        className="hover:text-blue-800 focus:outline-none"
        onClick={handleLoginClick}
      >
        <img src={headerAdd} alt="Add" className="h-10 w-10 text-black" />
      </button>
      <button
        className="hover:text-blue-800 focus:outline-none"
        onClick={handleLoginClick}
      >
        <img src={headerPlus} alt="Plus" className="h-10 w-10 text-black" />
      </button>
      <button
        className="hover:text-blue-800 focus:outline-none"
        onClick={handleLoginClick}
      >
        <img src={headerChat} alt="Chat" className="h-10 w-10 text-black" />
      </button>
    </div>
  );

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json');
        const { country } = response.data;
        setUserCountry(country);
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };

    fetchUserCountry();
  }, []);

  return (
    <header
      className={`fixed left-0 right-0 top-0 z-10 bg-transparent p-5 shadow-md transition-all duration-300`}
      style={{
        height: '8vh',
        backgroundColor: scrollPosition > 0 ? '#AE95B2' : 'transparent',
        opacity: scrollPosition > 0 ? '1' : 'var(--header-opacity)',
      }}
    >
      <nav className="flex w-full flex-col items-center justify-center">
        <div className="absolute left-2 top-2">
          <div style={{ opacity: 1 }}>
            <FontAwesomeIcon
              icon={faBars}
              onClick={() => setNavVisible(!isNavVisible)}
              className="block"
            />
          </div>
        </div>

        {isNavVisible ? (
          <div className="fixed left-0 top-0 z-50 flex h-screen w-screen flex-col items-center justify-center bg-white p-5 text-black">
            {navButtonsRow}
            {userCountry && (
              <div
                className="absolute right-2 top-2"
                onClick={(e) => e.preventDefault()}
              >
                <WorldFlag
                  className="flag-icon"
                  code={userCountry}
                  style={{ width: '2.5rem' }}
                />
              </div>
            )}
            {user && (
              <div className="mt-4 text-center text-white">
                <p className="text-lg font-bold">
                  Tervetuloa, {user.username}!
                </p>
              </div>
            )}
          </div>
        ) : null}
      </nav>
      {isLoginVisible &&
        portalRoot &&
        createPortal(<LoginWindow />, portalRoot)}
    </header>
  );
};

export default HeaderMobile;
