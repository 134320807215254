import React, { useState, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Root from './pages/Root';
import Login from './pages/Login';
import MobileRoot from './pages/MobileRoot';
import LoadingScreen from './components/Component/LoadingScreen'; // Import your custom loading screen component

import { AuthContext, User } from './authcontext';

const queryClient = new QueryClient();

export default function App() {
  const [user, setUser] = useState<User | null>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const handleLogin = (username: string, password: string) => {
    // Perform login logic and update the user state
  };

  const handleLogout = () => {
    // Perform logout logic and update the user state
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check the initial screen size

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Simulate async loading process
    setTimeout(() => {
      setIsLoading(false); // Set isLoading to false when loading is complete
    }, 1000); // Adjust the duration as needed
  }, []);

  if (isLoading) {
    // Render the loading screen while isLoading is true
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider value={{ user, setUser, handleLogin, handleLogout }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            {isMobile ? (
              <Route element={<MobileRoot />} path="/" />
            ) : (
              <Route element={<Root />} path="/" />
            )}
            <Route element={<About />} path="/about" />
            <Route element={<Login />} path="/login" />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
}
